
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { Route, NavigationGuardNext } from "vue-router";
import {
  Col,
  Row,
  Image,
  Search,
  Tabs,
  Tab,
  NoticeBar,
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  Icon,
  PullRefresh,
  Sticky,
  Popup,
  NavBar,
  Popover,
  Button,
  List,
  Dialog,
  CountDown,
  // Toast
} from "vant";
import { Mixin } from "@/core/mixins/mixin";
import eventBus from "@/utils/eventBus";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  name: "goodItem",
  components: {
    [CountDown.name]: CountDown,
    Mixin,
    [Col.name]: Col,
    [Row.name]: Row,
    [Image.name]: Image,
    [Search.name]: Search,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Icon.name]: Icon,
    [PullRefresh.name]: PullRefresh,
    [Sticky.name]: Sticky,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar,
    [Popover.name]: Popover,
    [Button.name]: Button,
    [List.name]: List,
    [Dialog.Component.name]: Dialog.Component,
    //  [Toast.Messa]:Toast.loading
  },
})
export default class goodItem extends Mixins(Mixin) {
  //判断是否是返回，不是就重新加载页面，是就是看缓存数据
  @Prop({})
  public item!: ObjectMap;
  @Prop()
  public isBackFrom!: string;
  @Prop()
  public showText!: string;
  @Prop()
  public status!: string;

  public scrollTop = 0;

  /**
   * 跳转详情页
   *
   * @param item 订单信息
   */
  goToDetail(item: ObjectMap): void {
    monitorEvent(
      "OrderList_ClickOrder",
      "点击订单列表的一个订单",
      item.orderStatusName
    ); // 埋点：点击订单列表的一个订单
    let path = `/mall/m-order/${item.orderId}`;
    // this.isBackFrom = path;

    this.scrollTop =
      document.getElementsByClassName("van-tabs__content")[0].scrollTop;
    let parames = {
      scrollTop: `${this.scrollTop}`,
      showText: `${this.showText}`,
    };
    eventBus.$emit("saveParames", parames);
    this.$router.push({
      path: path,
      query: {
        status: `${this.status}`,
      },
    });
  }
  goToLogo() {
    this.$router.push("/home");
  }
  finish(): void {
    setTimeout(() => {
      this.$emit("refresh"); //倒计时结束 刷新订单列表
    }, 500);
  }
}
