
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  Button,
  Dialog,
  // Toast
} from "vant";
import { Mixin } from "@/core/mixins/mixin";

@Component({
  name: "orderBottom",
  components: {
    Mixin,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class orderBottom extends Mixins(Mixin) {
  //判断是否是返回，不是就重新加载页面，是就是看缓存数据
  @Prop({})
  public item!: ObjectMap;

  changeOrderStatus(orderId: string, orderType: string, type: string): void {
    //商品订单
    if (orderType === "ORDER_GOOD") {
      if (type === "cancel") {
        Dialog.confirm({
          message: "您确定取消该订单吗？",
        })
          .then(() => {
            this.$toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
            });
            this.$api.orderApi.goodOrder.cancelOrder(orderId, (data) => {
              if (data.data === true) {
                this.$toast("取消成功");
                this.$emit("getOrderList");
              }
            });
          })
          .catch(() => {
            // on cancel
          });
      }
      if (type === "delete") {
        Dialog.confirm({
          message: "您确定删除该订单吗？",
        })
          .then(() => {
            this.$toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
            });
            this.$api.orderApi.goodOrder.deleteGoodOrder(orderId, (data) => {
              if (data.data === true) {
                this.$toast("删除成功");

                this.$emit("getOrderList");
              }
            });
          })
          .catch(() => {
            // on cancel
          });
      }
      if (type === "confirm") {
        Dialog.confirm({
          message: "确认收到货了吗？",
        })
          .then(() => {
            this.$toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
            });
            this.$api.orderApi.goodOrder.orderConfirmReceipt(
              orderId,
              ({ data }) => {
                if (data) {
                  this.$toast("收货成功");
                  this.$emit("getOrderList");
                }
              }
            );
          })
          .catch(() => {
            // on cancel
          });
      }
    }
    //演出订单
    if (orderType === "ORDER_TICKET") {
      if (type === "cancel") {
        Dialog.confirm({
          message: "您确定取消该订单吗？",
        })
          .then(() => {
            this.$toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
            });
            this.$api.orderApi.ticketOrder.cancelOrder(orderId, ({ data }) => {
              if (data) {
                this.productIdModule.resetProductId_PERSIST();
                this.$toast("取消成功");
                this.$emit("getOrderList");
              }
            });
          })
          .catch(() => {
            // on cancel
          });
      }
      if (type === "delete") {
        Dialog.confirm({
          message: "您确定删除该订单吗？",
        })
          .then(() => {
            this.$toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
            });
            this.$api.orderApi.ticketOrder.deleteOrder(orderId, (data) => {
              if (data.data === true) {
                this.$toast("删除成功");

                this.$emit("getOrderList");
              }
            });
          })
          .catch(() => {
            // on cancel
          });
      }
      if (type === "confirm") {
        Dialog.confirm({
          message: "确认收到货了吗？",
        })
          .then(() => {
            this.$toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
            });
            this.$api.orderApi.ticketOrder.orderConfirmReceipt(
              orderId,
              (data) => {
                if (data.data === true) {
                  this.productIdModule.resetProductId_PERSIST();
                  this.$toast("收货成功");
                  this.$emit("getOrderList");
                }
              }
            );
          })
          .catch(() => {
            // on cancel
          });
      }
    }
  }
  toPay(val: ObjectMap): void {
    let path = "/payconfirm";
    // 翼支付
    if (this.$route.query.templateType == "BEST_PAY") {
      let bestpayParam: payment.PaymentOrderDTO = {
        channelEnum: "PLAT_H5",
        orderType: "SHOW",
        payMethodEnum: "BEST_PAY",
        orderId: val.orderId,
        distributionChannelId: this.$route.query
          .distributionChannelId as string,
        distributionSeriesId: this.$route.query.distributionSeriesId as string,
        sceneTypeEnum: this.$AppSource === "ios" ? "IOS_WAP" : "ANDROID_WAP",
        cancelOlderOrder: false,
      };
      this.$api.paymentApi.bestPayPaymentOrder.bestPayPaymentOrder(
        bestpayParam,
        ({ data }) => {
          // 跳转到翼支付页面
          this.$router.replace({
            path: "/other/pay",
            query: {
              data: JSON.stringify(data),
              type: "BEST_PAY",
              orderId: val.orderId,
              orderType: "SHOW",
              productSourceEnum: val.productSourceEnum,
              productId: val.productId,
            },
          });
        }
      );
    } else {
      // this.isBackFrom = path;
      let str = {
        orderId: val.orderId!,
        orderType: val.orderType,
      };
      this.$router.push({ path, query: { ...str } });
    }
  }
  gotoComment() {
    this.$router.push(
      `/write-comment/${this.item.productId}?orderId=${this.item.orderId}&productName=${this.item.productName}`
    );
  }
}
