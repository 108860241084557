var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"order-content ticket",on:{"click":function($event){return _vm.goToDetail(_vm.item)}}},[_c('div',{staticClass:"order-top"},[_c('div',{staticClass:"left",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.goToLogo()}}},[_c('van-image',{staticClass:"the-logo",attrs:{"fit":_vm.imageFit,"src":_vm.item.theatreLogo
            ? _vm.item.theatreLogo
            : require('@/assets/images/Public/shop.png')}}),_c('span',{staticClass:"the-tit"},[_c('span',[_vm._v(" "+_vm._s(_vm.item.theatreName)+" ")]),_c('van-icon',{attrs:{"name":"arrow","size":".28rem","color":"#333"}})],1)],1),_c('div',{staticClass:"right",style:({
        color: _vm.item.orderStatus == 'PEND_PAYMENT' ? '#f81313' : '#999',
      })},[(_vm.item.orderStatus === 'PEND_PAYMENT')?_c('van-count-down',{staticStyle:{"display":"none"},attrs:{"time":Number(_vm.item.orderExpireTime)},on:{"finish":_vm.finish}}):_vm._e(),_vm._v(" "+_vm._s(_vm.item.orderStatusName)+" ")],1)]),_c('van-row',{staticClass:"order-detail"},[_c('van-col',{staticClass:"left"},[_c('van-image',{staticClass:"order-img",attrs:{"fit":_vm.imageFit,"src":`${
          _vm.item.projectImg
            ? _vm.item.projectImg
            : require('@/assets/images/placeholder/no-project.png')
        }`}}),_c('van-image',{staticClass:"tit",attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/PerOrder/ticket.png')}})],1),_c('van-col',{staticClass:"right"},[_c('van-row',{staticClass:"order-title"},[_vm._v(" "+_vm._s(_vm.item.productName)+" ")]),(!(!_vm.item.cityName && !_vm.item.placeName))?_c('van-row',{staticClass:"order-desc"},[_vm._v(" "+_vm._s(_vm.item.cityName)),(!!_vm.item.cityName || !!_vm.item.placeName)?_c('span',[_vm._v(" | ")]):_vm._e(),_vm._v(_vm._s(_vm.item.placeName)+" ")]):_vm._e(),_c('van-row',{staticClass:"order-desc"},[_c('van-icon',{attrs:{"name":"clock-o"}}),_vm._v(" "+_vm._s(_vm.item.showTimeStr)+" ")],1),_c('div',{staticClass:"order-detail-num"},[_c('van-col',{staticClass:"num-left"},[_c('span',{staticClass:"order-number"},[_vm._v("共"+_vm._s(_vm.item.num)+"张")])]),_c('van-col',{staticClass:"num-right"},[_c('div',{staticClass:"order-price",domProps:{"innerHTML":_vm._s(_vm.item.unitPrice)}})])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }